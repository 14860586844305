<template>
  <v-container
    fluid
    py-0
    class="blue"
    style="position: fixed; overflow: scroll; height: 100vh"
  >
    <v-row align="center" justify="center" style="height: 100vh">
      <v-card class="pa-8 ma-5" width="380">
        <v-card-title class="d-flex justify-center" v-if="!message">
          sweeep
        </v-card-title>
        <div v-if="message">
          <div>
            <span
              >{{ $t('message.signup_sent_message_line1')
              }}{{ $t('message.signup_sent_message_line2') }}</span
            >
          </div>
          <div class="pt-5">
            <span
              >{{ $t('message.signup_sent_message_line3')
              }}{{ $t('message.signup_sent_message_line4') }}</span
            >
          </div>
        </div>

        <v-dialog v-model="isAddCompany" max-width="350px">
          <v-card class="sweeep-dialog">
            <v-card-title>確認</v-card-title>
            <v-card-text class="pa-4"
              >入力されたメールアドレスはすでに登録済みです。<br />このメールアドレスを使って別の会社の利用を開始しますか？</v-card-text
            >
            <v-toolbar flat>
              <v-spacer />
              <v-btn
                @click="
                  () => {
                    isAddCompany = false
                  }
                "
                class="mr-2"
              >
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                @click="
                  submit()
                  isAddCompany = false
                "
                dark
              >
                別の会社用に登録
              </v-btn>
            </v-toolbar>
          </v-card>
        </v-dialog>

        <div v-if="!message">
          <v-form ref="form" v-model="valid">
            <v-text-field
              label="会社名 / 個人名 / 屋号"
              v-model="organization_name"
              :rules="nameRules"
              required
              validate-on-blur
              hint="正式名でご登録。読取精度に影響します"
              prepend-inner-icon="feather-home"
              class="mb-5"
            ></v-text-field>
            <v-text-field
              label="メールアドレス"
              v-model="email"
              :rules="emailRules"
              required
              validate-on-blur
              prepend-inner-icon="feather-mail"
              class="mb-5"
            ></v-text-field>
            <v-btn
              :loading="loading"
              block
              color="blue"
              v-on:click="submit()"
              dark
              >{{ $t('message.signUpAction') }}</v-btn
            >
            <p class="fs-10 mb-4 mt-2">
              <a href="https://sweeep.ai/terms" target="_blank">{{
                $t('message.termsOfService')
              }}</a>
              {{ $t('message.bySigningUpYouAgreeTo') }}
            </p>
            <v-btn block to="/session/login">
              {{ $t('message.havingAnAccount') }}
            </v-btn>
          </v-form>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import SessionSliderWidget from 'Components/Widgets/SessionSlider'
import AppConfig from 'Constants/AppConfig'
import AuthService from '../../auth/AuthService'

export default {
  components: {
    SessionSliderWidget,
  },
  data() {
    var t = this
    return {
      agree: false,
      dialog: false,
      organization_name: '',
      individual: '',
      isAddCompany: false,
      message: false,
      valid: false,
      name: '',
      nameRules: [
        (v) => !!v || t.$t('message.form_input_required'),
        (v) =>
          (v && v.replace(/\s/g, '').length >= 1) ||
          t.$t('message.form_input_required'),
        (v) => v.length <= 100 || '100' + t.$t('message.character_max_length'),
      ],
      email: '',
      emailRules: [
        (v) => !!v || '※この項目は必須です',
        (v) =>
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            v
          ) || '※正しい形式で入力ください',
        (v) => v.length <= 100 || '100' + t.$t('message.character_max_length'),
      ],
      password: '',
      passwordRules: [(v) => !!v || 'Password is required'],
      appLogo: AppConfig.appLogo2,
      brand: AppConfig.brand,
      loading: false,
      pathName: window.location.pathname,
    }
  },
  mounted() {},
  methods: {
    agreeTerms() {
      this.agree = true
    },
    submit() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true
          let apiUrl = this.$store.getters.apiSignUpFunction
          let hostUrl =
            process.env.VUE_APP_HOSTURL_EMAIL + 'session/sign-up/confirm/'
          let isSupportCreated = this.pathName === '/session/cs-entry'
          let data = {
            business_form: this.isAddCompany,
            email: this.email,
            organization_name: this.organization_name,
            url: hostUrl,
            isSupportCreated: isSupportCreated,
          }
          this.$store
            .dispatch('apiSignUpUser', {
              data,
              apiUrl,
            })
            .then((response) => {
              if (response.data.error) {
              } else if (response.data.addCompany) {
                this.isAddCompany = true
              } else if (!!response.data.to_signup) {
                window.location.href = response.data.to_signup
              } else {
                this.message = true
              }
              this.loading = false
            })
        }
      } catch (e) {
        console.log(e)
      }
    },
    signInWithFacebook() {
      this.$store.dispatch('signinUserWithFacebook', {
        router: this.$router,
      })
    },
    signInWithGoogle() {
      this.$store.dispatch('signinUserWithGoogle', {
        router: this.$router,
      })
    },
    signInWithTwitter() {
      this.$store.dispatch('signinUserWithTwitter', {
        router: this.$router,
      })
    },
    signInWithGithub() {
      this.$store.dispatch('signinUserWithGithub', {
        router: this.$router,
      })
    },
  },
}
</script>
